import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import faqs from "../../constants/faq";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-faq"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function FAQ() {
    const {
        i18n: { language },
        t,
    } = useTranslation();

    const allFaqIndexes = Array.from(
        { length: faqs.length },
        (item, index) => (item = index + 1)
    );

    const [openedFaq, openFaq] = useState(null);
    const [searchResultIndexes, updateSearchResultIndexes] =
        useState(allFaqIndexes);

    const handleClickOnFaq = (clickedFaq) => {
        if (clickedFaq === openedFaq) {
            openFaq(null);
        } else {
            openFaq(clickedFaq);
        }
    };

    const handleSearch = (event) => {
        // Close the opened faq
        openFaq(null);

        let searchedWord = event.target.value;

        // Show all if the user does not specify a specific word
        if (searchedWord === "") {
            updateSearchResultIndexes(allFaqIndexes);
        }

        // Searching by lowercase
        searchedWord = searchedWord.toLowerCase();

        // Update faqs visibility based on searched word
        updateSearchResultIndexes(
            faqs.map((faq, index) => {
                const question = faq.question[language].toLowerCase();
                if (question.search(searchedWord) !== -1) {
                    return index;
                }

                const answer = faq.answer[language].toLowerCase();
                if (answer.search(searchedWord) !== -1) {
                    return index;
                }

                return null;
            })
        );
    };

    return (
        <Layout>
            <Seo title="FAQ" />
            <Container>
                <StaticImage
                    src="../../../assets/images/campanello.jpg"
                    alt="FAQ"
                    className="rounded-4xl"
                    aspectRatio={16 / 10}
                    layout="fullWidth"
                    transformOptions={{ fit: "cover" }}
                />
                <h1 className="mt-8">FAQ</h1>
                <p>{t("page-faq:howToUseTheList")}</p>
                <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4">
                    <label htmlFor="search" className="font-bold">
                        {t("page-faq:search")}:
                    </label>
                    <input
                        id="search"
                        type="search"
                        placeholder={t("page-faq:searchPlaceholder")}
                        onInput={handleSearch}
                        className="sm:ml-4 w-full"
                    />
                </div>
                <ul>
                    {faqs.map((faq, index) => {
                        if (!searchResultIndexes.includes(index)) {
                            return null;
                        }

                        return (
                            <div key={index}>
                                <hr className="my-4 border-t-4 border-gold rounded-full" />
                                <li>
                                    <div
                                        className="uppercase flex items-center cursor-pointer"
                                        onClick={() => handleClickOnFaq(index)}
                                        aria-hidden="true"
                                    >
                                        <div>
                                            {index === openedFaq ? (
                                                <MdRemoveCircleOutline
                                                    size={32}
                                                    className="text-gold"
                                                />
                                            ) : (
                                                <MdAddCircleOutline
                                                    size={32}
                                                    className="text-gold"
                                                />
                                            )}
                                        </div>
                                        <b className="ml-4">
                                            {faq.question[language]}
                                        </b>
                                    </div>
                                    {index === openedFaq && (
                                        <p className="my-4">
                                            {faq.answer[language]}
                                        </p>
                                    )}
                                </li>
                            </div>
                        );
                    })}
                </ul>
            </Container>
        </Layout>
    );
}

const faqs = [
    {
        question: {
            it: "A che ora posso avere la camera?",
            en: "What time can I have the room?",
        },
        answer: {
            it: "La camera è pronta dalle ore 12:00 ma in caso il cliente arrivi prima in struttura potrà lasciare il proprio bagaglio in custodia presso la reception",
            en: "The room is ready from 12:00 but in case the customer arrives earlier in the structure he can leave his luggage in custody at the reception",
        },
    },
    {
        question: {
            it: "Il giorno della partenza, a che ora devo lasciare la camera?",
            en: "On the day of departure, what time should I leave the room?",
        },
        answer: {
            it: "La camera deve essere lasciata entro le ore 10:30 per darci la possibilità di prepararla al meglio per l'ospite successivo",
            en: "The room must be vacated by 10:30 AM to give us the opportunity to better prepare it for the next guest",
        },
    },
    {
        question: {
            it: "Come arrivo all'Hotel Moderno dalla stazione dei treni di Assisi?",
            en: "How do I get to the Hotel Moderno from the Assisi train station?",
        },
        answer: {
            it: "Uscendo dalla stazione di Assisi a sinistra troverete subito l'Hotel Moderno",
            en: "Leaving the Assisi station on the left you will immediately find the Hotel Moderno",
        },
    },
    {
        question: {
            it: "Qual'è la fermata bus più vicina per raggiungere il centro della città?",
            en: "Which is the nearest bus stop to reach the city center?",
        },
        answer: {
            it: "L'Hotel Moderno per la sua posizione strategica è l'ideale per tutti gli spostamenti con i servizi pubblici, treno, bus e taxi disponibili ogni giorno di fronte alla struttura",
            en: "The Hotel Moderno due to its strategic position is ideal for all trips with public services, train, bus and taxi available every day in front of the structure",
        },
    },
    {
        question: {
            it: "Dove posso acquistare il biglietto per il bus?",
            en: "Where can I buy the bus ticket?",
        },
        answer: {
            it: "All'interno della stazione di Assisi troverete l'edicola dove potrete acquistare i biglietti per il bus",
            en: "Inside the Assisi station you will find the newsstand where you can buy tickets for the bus",
        },
    },
    {
        question: {
            it: "Quanto costa il biglietto del bus per raggiungere il centro di Assisi?",
            en: "How much does the bus ticket cost to reach the center of Assisi?",
        },
        answer: {
            it: "Il biglietto per la singola corsa costa € 1.30, la frequenza delle corse è di ogni 30 minuti, il tragitto dura circa 10-15 minuti",
            en: "The ticket for the single journey costs € 1.30, the frequency of the races is every 30 minutes, the journey takes about 10-15 minutes",
        },
    },
    {
        question: {
            it: "C'è l'ascensore in hotel?",
            en: "Is there an elevator in the hotel?",
        },
        answer: {
            it: "L'hotel è provvisto di ascensore per raggiungere i piani superiori",
            en: "The hotel has a lift to reach the upper floors",
        },
    },
    {
        question: {
            it: "C'è un supermarket nelle vicinanze?",
            en: "Is there a supermarket nearby?",
        },
        answer: {
            it: "A 200 metri dall'hotel c'è un fornitissimo supermarket aperto con orario continuato dalle ore 09:00 alle 20:00",
            en: "At 200 meters from the hotel there is a well-stocked supermarket open non-stop from 09:00 to 20:00",
        },
    },
    {
        question: {
            it: "Quanto costa la tassa di soggiorno? E come si può pagare?",
            en: "How much is the tourist tax? And how can you pay?",
        },
        answer: {
            it: "Agli hotel classe 3 stelle della città di Assisi viene applicata una tassa di soggiorno pari a €1,50 a persona al giorno dalla quale sono esenti i bambini fino a 12 anni di età. Se il vostro soggiorno avrà una durata di oltre 3 giorni dal quarto giorno in poi non sarà più necessario pagare la tassa di soggiorno. Il pagamento avviene direttamente in struttura e in contanti, non si può effettuare il pagamento della stessa tramite carta di credito o bancomat",
            en: "A stay tax of € 1.50 per person per day is applied to 3-star hotels in the city of Assisi, from which children up to 12 years of age are exempt. If your stay lasts more than 3 days from the fourth day onwards, it will no longer be necessary to pay the tourist tax. Payment is made directly in the structure and in cash, it cannot be paid by credit card or debit card",
        },
    },
    {
        question: {
            it: "I cani sono ammessi?",
            en: "Are dogs allowed?",
        },
        answer: {
            it: "Certo, siamo un hotel pet friendly purchè il cane soggiorni nel rispetto della struttura",
            en: "Of course, we are a pet friendly hotel as long as the dog stays within the structure",
        },
    },
    {
        question: {
            it: "Quali sono gli orari dei pasti?",
            en: "What are the meal times?",
        },
        answer: {
            it: "Colazione ore 7.00-10.00. Pranzo ore 12.00-13.30. Cena ore 19.30-21.00",
            en: "Breakfast 7.00-10.00. Lunch 12.00-13.30. Dinner 19.30-21.00",
        },
    },
    {
        question: {
            it: "Dove posso lasciare l'auto?",
            en: "Where can I leave the car?",
        },
        answer: {
            it: "L'hotel dispone di ampio parcheggio privato e gratuito dove poter lasciare l'auto",
            en: "The hotel has a large free private car park where you can leave your car",
        },
    },
    {
        question: {
            it: "Avete disponibilità di una culla?",
            en: "Do you have availability of a cot?",
        },
        answer: {
            it: "Si, disponiamo di culla con un piccolo supplemento",
            en: "Yes, we have a cot with a small supplement",
        },
    },
    {
        question: {
            it: "Avete disponibilità di prodotti per celiaci o intolleranti?",
            en: "Do you have availability of products for celiacs or intolerant people?",
        },
        answer: {
            it: "Certo, siamo pronti a tutte le esigenze del cliente, abbiamo a disposizione prodotti per celiaci e intolleranti al lattosio qualora ce ne fosse necessità",
            en: "Of course, we are ready for all customer needs, we have products for celiacs and lactose intolerant if needed.",
        },
    },
    {
        question: {
            it: "Realizzate piatti per vegetariani o vegani?",
            en: "Do you make dishes for vegetarians or vegans?",
        },
        answer: {
            it: "Si, i nostri chef realizzano piatti per vegetariani e vegani",
            en: "Yes, our chefs make dishes for vegetarians and vegans",
        },
    },
    {
        question: {
            it: "La colazione è a buffet?",
            en: "Is the breakfast buffet?",
        },
        answer: {
            it: "Si, il nostro hotel offre una ricca colazione a buffet dolce e salata. Tutti i prodotti dolci che troverete a disposizione quali cornetti, crostate, muffin, ciambelloni sono realizzati da noi con prodotti freschi e genuini, disponibili anche in versione senza lattosio e glutine",
            en: "Yes, our hotel offers a rich sweet and savory buffet breakfast. All the sweet products that you will find available such as croissants, pies, muffins, donuts are made by us with fresh and genuine products, also available in a lactose and gluten-free version",
        },
    },
    {
        question: {
            it: "La reception è operativa H24?",
            en: "Is the reception open 24 hours a day?",
        },
        answer: {
            it: "No, la nostra reception chiude alle 23 circa ma ai nostri clienti vengono rilasciati i pass della porta centrale per muoversi in libertà, il cliente può rientrare all'orario che desidera",
            en: "No, our reception closes at about 11pm but our customers are given the passes of the central door to move freely, the customer can return at the time they want",
        },
    },
    {
        question: {
            it: "C'è Wi-Fi nell'hotel?",
            en: "Is there Wi-Fi in the hotel?",
        },
        answer: {
            it: "Si, è presente Wi-Fi gratuito in tutta la struttura",
            en: "Yes, there is free Wi-Fi throughout the hotel",
        },
    },
    {
        question: {
            it: "Si può fumare nelle stanze?",
            en: "Is it possible to smoke in the rooms?",
        },
        answer: {
            it: "Come prevede la legge non si può fumare nelle stanze, ma l'hotel dispone di camere fumatori per cui se necessario si può farne richiesta e vi verranno assegnate stanze apposite",
            en: "As required by law, you cannot smoke in the rooms, but the hotel has smoking rooms for which you can request them if necessary and you will be assigned special rooms",
        },
    },
    {
        question: {
            it: "Ogni quanto avviene il cambio biancheria?",
            en: "How often is the linen change?",
        },
        answer: {
            it: "Il cambio biancheria toilet avviene quotidianamente mentre le lenzuola vengono cambiate in base alla durata del soggiorno",
            en: "The toilet linen is changed daily while the sheets are changed according to the length of the stay",
        },
    },
];

export default faqs;
